import React from "react";
import { Link } from "gatsby";
import { withLayout } from "../parts/Layout";

// markup
const NotFoundPage = () => {
  return (
    <>
      <title>Not found</title>
      <div className="flex-1 flex flex-col min-h-screen bg-depalmaBlue text-depalmaWhite justify-between">
        <div className="flex-1"></div>
        <main className="mx-auto flex-0 text-center items-center">
          <h1 className="text-center text-2xl mb-4">Page not found</h1>          
          <p className="text-center mb-4">
            Sorry{" "}
            <span role="img" aria-label="Pensive emoji">😔</span>{" "}
            we couldn’t find what you were looking for.
          </p>

          <p>
            <Link to={'/'} className="px-4 py-2 border bg-black bg-opacity-50 hover:bg-opacity-75">
              Go Back
            </Link>
          </p>
        </main>
        <div className="flex-1"></div>
      </div>
    </>
  );
};

export default withLayout(NotFoundPage);
